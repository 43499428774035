.TestimonialCard {
  text-align: center;
  padding: 90px 30px 25px 30px;
  background: rgba(255, 255, 255, 0.13);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  margin: 1rem;
  color: white;
  height: 300px;
}
.TestimonialCard div {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 32px;
}
.TestimonialCard span {
  display: flex;
  justify-content: center;
  align-items: center;
}
img[alt="star"] {
  display: inline;
}
.Testimonial {
  margin-top: 1rem;
  width: 100vw;
  overflow: hidden;
  background: var(--clr-grad);
}
.TestimonialCard h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.TestimonialCard span {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #f5b100;
}
