@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

:root {
  --clr-primary: #0667ab;
  --clr-bodytext: #021e31;
  --clr-grey: #0667ab;
  --clr-body: #05243a;
  --clr-grad: linear-gradient(90deg, #005c97 0%, #363795 100%);
}
body {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  width: 100vw;
  overflow-x: hidden;
}
.btn-1 {
  background-color: var(--clr-primary);
  color: #fff;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 2.25rem;
  padding: 0.5rem 0.8rem;
  border: none;
  box-shadow: 0px 16px 40px rgba(57, 57, 57, 0.08);
  border-radius: 6px;
}
.center-div {
  width: min(76rem, 92%);
  margin: 0 auto;
}
