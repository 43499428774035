.Email input {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  padding: 10px 16px;
  background: #fff;
  border: none;
  outline-color: transparent;
  width: 100%;
}

.Email button {
  background: var(--clr-grad);
  border: none;
  outline-color: transparent;
  color: #fff;
  padding: 10px;
  width: 60%;
}
.Email {
  display: flex;
  background: #ffffff;
  border-radius: 10px;
  border: 2px solid #ffff;
  overflow: hidden;
}
@media only screen and (max-width: 880px) {
  .Email input,
  .Email button {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
  }
}
